import React, { useState } from "react";

export const AccessoriesContext = React.createContext({
  list: [],
  setList: () => {},
});

export const AccessoriesContextProvider = (props) => {
  const setList = (list) => {
    setState({ ...state, list: list });
  };

  const initState = {
    list: [],
    setList: setList,
  };

  const [state, setState] = useState(initState);

  return (
    <AccessoriesContext.Provider value={state}>
      {props.children}
    </AccessoriesContext.Provider>
  );
};
