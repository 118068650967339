import { registerUser, updateUser } from "../../../api/userservices";

const registerUserRequest = (data) => async (dispatch) => {
  const response = await dispatch(registerUser(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

const updateUserRequest = (data) => async (dispatch) => {
  const response = await dispatch(updateUser(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

export { registerUserRequest, updateUserRequest };
