import { useEffect, useState } from "react";
import "react-input-range/lib/css/index.css";
import { useDispatch } from "react-redux";
import LoadingHud from "../../hud/loadinghud";
import BreadcrumbCom from "../BreadcrumbCom";
import SectionStyleTwo from "../Helpers/SectionStyleTwo";
import Layout from "../Partials/Layout";

export default function EventsPage() {
  const [isLoading, setIsLoading] = useState(false);

  const [products, setProducts] = useState([
    {
      id: 24,
      name: "SanthoshamAwards",
      description:
        "The 21st edition of these prestigious awards is now slated to be held at the JRC Convention in Hyderabad. The organisers announced that the award show will begin from 3:30 PM onwards and will provide 12 hours of nonstop entertainment.",
      category_id: "4",
      subcategory_id: "3",
      quantity: "10",
      mrp: "52498.95",
      c_gst: "2.5",
      s_gst: "2.5",
      discounted_price: "49999",
      created_at: "0000-00-00 00:00:00",
      updated_at: "2023-10-23T08:31:26.000Z",
      deleted_at: "2023-10-18T12:33:02.000Z",
      status: "active",
    },
    {
      id: 24,
      name: "ComedyEvent",
      description:
        "We teamed up with the folks at Blacklist Promotions to bring you a handpicked line-up of local comedians. Not for the fainthearted, and not for the easily offended - these comedians tell it like it is, local stories from real people, with a diversity on stage that matches our community. ",
      category_id: "4",
      subcategory_id: "3",
      quantity: "10",
      mrp: "52498.95",
      c_gst: "2.5",
      s_gst: "2.5",
      discounted_price: "49999",
      created_at: "0000-00-00 00:00:00",
      updated_at: "2023-10-23T08:31:26.000Z",
      deleted_at: "2023-10-18T12:33:02.000Z",
      status: "active",
    },
    {
      id: 24,
      name: "BigBang",
      description:
        "Elevate Your Saturday Night at Big Bang Sky Deck! Join us this Saturday for a sensational evening of Live Music featuring the amazing DJ Suman and DJ MoneSDeck",
      category_id: "4",
      subcategory_id: "3",
      quantity: "10",
      mrp: "52498.95",
      c_gst: "2.5",
      s_gst: "2.5",
      discounted_price: "49999",
      created_at: "0000-00-00 00:00:00",
      updated_at: "2023-10-23T08:31:26.000Z",
      deleted_at: "2023-10-18T12:33:02.000Z",
      status: "active",
    },
    {
      id: 24,
      name: "SundayNight",
      description:
        "Join Us For An Electrifying Sunday DJ Night At The Big Bang Sky Deck & Lounge! DJ Manik And DJ Money Are Teaming Up To Bring You An Unforgettable Night Of Beats And Rhythm. It's Going To Be A Night Of Non-Stop Music, Dancing, And Good Vibes. Bring Your Friends, Let Loose, And Create Unforgettable Memories.  See You There!",
      category_id: "4",
      subcategory_id: "3",
      quantity: "10",
      mrp: "52498.95",
      c_gst: "2.5",
      s_gst: "2.5",
      discounted_price: "49999",
      created_at: "0000-00-00 00:00:00",
      updated_at: "2023-10-23T08:31:26.000Z",
      deleted_at: "2023-10-18T12:33:02.000Z",
      status: "active",
    },
  ]);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 60);
    // getAllProducts();

    //eslint-disable-next-line
  }, []);

  //   const getAllProducts = async () => {
  //     setIsLoading(true);
  //     await dispatch(
  //       getAllProductsRequest(
  //         { userId: localStorage.getItem("userId") },
  //         dispatch
  //       )
  //     ).then((response) => {
  //       setIsLoading(false);
  //       if (response !== undefined) {
  //         console.log(response.result);
  //         setProducts(response.result);
  //       }
  //     });
  //   };

  return (
    <>
      {isLoading && <LoadingHud />}
      <Layout>
        <div className="products-page-wrapper w-full">
          <div className="container-x mx-auto">
            <BreadcrumbCom />
            <div className="w-full lg:flex lg:space-x-[30px]">
              <div className="flex-1">
                <SectionStyleTwo type={3} products={products} isEvents={true} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
