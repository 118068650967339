import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import BreadcrumbCom from "../components/BreadcrumbCom";
import DataIteration from "../components/Helpers/DataIteration";
import Layout from "../components/Partials/Layout";
import { AccessoriesContext } from "../contextProviders/accessoriesContextProvider";

import IcoCart from "../components/Auth/Profile/icons/IcoCart";
import LoadingHud from "../hud/loadinghud";
import SubCategoriesList from "./tabs/subcategorieslist";

export default function Accessories() {
  const navigate = useNavigate();
  const location = useLocation();
  const [accessories, setAccessories] = useContext(AccessoriesContext);
  const getHashContent = location.hash.split("#");
  const [active, setActive] = useState("helmets");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setActive(
      getHashContent && getHashContent.length > 1
        ? getHashContent[1]
        : "helmets"
    );
  }, [getHashContent]);

  const validateSubCategories = (e) => {
    if (e.name.replace(/\s/g, "") === active) {
      return <SubCategoriesList subCatId={e.id} />;
    }
  };

  const validatePath = (e) => {
    if (e.name.replace(/\s/g, "") === active) {
      return (
        <SubCategoriesList
          subCatId={e.id}
          loadingHandler={(e) => {
            setIsLoading(e);
          }}
        />
      );
    }
  };
  return (
    <>
      {isLoading && <LoadingHud />}

      <Layout childrenClasses="pt-0 pb-0">
        <div className="profile-page-wrapper w-full">
          <div className="container-x mx-auto">
            <div className="w-full my-10">
              <BreadcrumbCom
                paths={[
                  { name: "home", path: "/" },
                  { name: "accessories", path: "/accessories" },
                ]}
              />
              <div className="w-full bg-white px-10 py-9">
                <div className="title-area w-full flex justify-between items-center">
                  <h1 className="text-[22px] font-bold text-qblack">
                    Accessories
                  </h1>
                </div>
                <div className="profile-wrapper w-full mt-8 flex space-x-10">
                  <div className="w-[236px] min-h-[600px] border-r border-[rgba(0, 0, 0, 0.1)]">
                    <div className="flex flex-col space-y-10">
                      <DataIteration
                        datas={accessories}
                        startLength={0}
                        endLength={accessories.length}
                      >
                        {({ datas }) => (
                          <div key={datas.id}>
                            <ul>
                              <li>
                                <div className="item group">
                                  <Link
                                    to={
                                      "/accessories#" +
                                      datas.name.replace(/\s/g, "")
                                    }
                                  >
                                    <button
                                    //   onClick={() => validateSubCategories(datas)}
                                    >
                                      <div className="flex space-x-3 items-center text-qgray hover:text-qblack">
                                        <span>
                                          <IcoCart />
                                        </span>
                                        <span className=" font-normal text-base">
                                          {datas.name}
                                        </span>
                                      </div>
                                    </button>
                                  </Link>
                                </div>
                              </li>
                            </ul>
                          </div>
                        )}
                      </DataIteration>
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className="item-body dashboard-wrapper w-full">
                      <DataIteration
                        datas={accessories}
                        startLength={0}
                        endLength={accessories.length}
                      >
                        {({ datas }) => validatePath(datas)}
                      </DataIteration>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
