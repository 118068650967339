import { useState } from "react";

export default function InputQuantityCom({ handler, productQuantity = 1 }) {
  const [quantity, setQuantity] = useState(productQuantity);
  const increment = () => {
    setQuantity((prev) => prev + 1);
    updateHandler(quantity + 1);
  };
  const decrement = () => {
    if (quantity > 1) {
      setQuantity((prev) => prev - 1);
      updateHandler(quantity - 1);
    }
  };

  const updateHandler = (value) => {
    handler(value);
  };
  return (
    <div className="w-[100px] h-[40px] px-[8px] flex items-center border border-qgray-border">
      <div className="flex justify-between items-center w-full">
        <button
          onClick={decrement}
          type="button"
          className="text-base text-qgray w-[30px]"
        >
          -
        </button>
        <span className="text-qblack p-[10px]">{quantity}</span>
        <button
          onClick={increment}
          type="button"
          className="text-base text-qgray w-[30px]"
        >
          +
        </button>
      </div>
    </div>
  );
}
