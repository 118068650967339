import { useNavigate } from "react-router-dom";
import Star from "../icons/Star";

export default function ProductCardStyleOne({ datas, type }) {
  const navigate = useNavigate();
  const available =
    (datas.cam_product_sale /
      (datas.cam_product_available + datas.cam_product_sale)) *
    100;

  const showDetails = (e) => {
    navigate("/single-product", { state: e });
  };
  return (
    <>
      <div
        className="product-card-one w-full h-full bg-white relative group overflow-hidden"
        style={{ boxShadow: "0px 15px 64px 0px rgba(0, 0, 0, 0.05)" }}
        onClick={() => showDetails(datas)}
      >
        <div
          className="product-card-img w-full h-[300px]"
          style={{
            background: `url(${
              process.env.PUBLIC_URL
            }/assets/images/${datas.name.toLowerCase()}1.png) no-repeat center`,
            backgroundSize: "contain",
          }}
        >
          {/* product available progress */}
          {datas.campaingn_product && (
            <>
              <div className="px-[30px] absolute left-0 top-3 w-full">
                <div className="progress-title flex justify-between ">
                  <p className="text-xs text-qblack font-400 leading-6">
                    Prodcuts Available
                  </p>
                  <span className="text-sm text-qblack font-600 leading-6">
                    {datas.cam_product_available}
                  </span>
                </div>
                <div className="progress w-full h-[5px] rounded-[22px] bg-primarygray relative overflow-hidden">
                  <div
                    style={{
                      width: `${
                        datas.campaingn_product ? 100 - available : 0
                      }%`,
                    }}
                    className={`h-full absolute left-0 top-0  ${
                      type === 3 ? "bg-fuchsia-900" : "bg-qyellow"
                    }`}
                  ></div>
                </div>
              </div>
            </>
          )}
          {/* product type */}
          {datas.product_type && !datas.campaingn_product && (
            <div className="product-type absolute right-[14px] top-[17px]">
              <span
                className={`text-[9px] font-700 leading-none py-[6px] px-3 uppercase text-white rounded-full tracking-wider ${
                  datas.product_type === "popular"
                    ? "bg-[#19CC40]"
                    : "bg-qyellow"
                }`}
              >
                {datas.product_type}
              </span>
            </div>
          )}
        </div>
        <div className="product-card-details px-[30px] pb-[40px] relative">
          {/* add to card button */}
          <div className="absolute w-full h-10 px-[30px] left-0 top-40 group-hover:top-[85px] transition-all duration-300 ease-in-out">
            <button type="button" className="blue-btn">
              <div className="flex items-center space-x-3">
                <span>Details</span>
              </div>
            </button>
          </div>
          <div className="reviews flex space-x-[1px] mb-3 mt-2">
            {Array.from(Array(datas.review), () => (
              <span key={datas.review + Math.random()}>
                <Star />
              </span>
            ))}
          </div>

          <p className="title mb-2 text-[15px] font-600 text-qblack leading-[24px] line-clamp-2 hover:text-blue-600">
            {datas.name}
          </p>

          <p className="price">
            <span className="main-price text-qgray line-through font-600 text-[18px]">
              ₹{datas.mrp}
            </span>
            <span className="offer-price text-qred font-600 text-[18px] ml-2">
              ₹{datas.discounted_price}
            </span>
          </p>
        </div>
        {/* quick-access-btns */}
        {/* <div className="quick-access-btns flex flex-col space-y-2 absolute group-hover:right-4 -right-10 top-20  transition-all duration-300 ease-in-out">
          <a href="#">
            <span className="w-10 h-10 flex justify-center items-center bg-primarygray rounded">
              <QuickViewIco />
            </span>
          </a>
          <a href="#">
            <span className="w-10 h-10 flex justify-center items-center bg-primarygray rounded">
              <ThinLove />
            </span>
          </a>
          <a href="#">
            <span className="w-10 h-10 flex justify-center items-center bg-primarygray rounded">
              <Compair />
            </span>
          </a>
        </div> */}
      </div>
    </>
  );
}
