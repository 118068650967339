import { useEffect, useState } from "react";
import { FaWpforms } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BreadcrumbCom from "../components/BreadcrumbCom";
import FourZeroFour from "../components/FourZeroFour";
import DataIteration from "../components/Helpers/DataIteration";
import Layout from "../components/Partials/Layout";
import EMI from "./tabs/emi";
import Insurance from "./tabs/insurance";
import Warranty from "./tabs/warranty";

export default function Assurance() {
  const [assurance, setAssurance] = useState([
    { name: "Warranty", id: "1" },
    { name: "Insurance", id: "2" },
    { name: "EMI", id: "3" },
  ]);
  const navigate = useNavigate();
  const location = useLocation();
  const getHashContent = location.hash.split("#");
  const [active, setActive] = useState("warranty");

  useEffect(() => {
    setActive(
      getHashContent && getHashContent.length > 1
        ? getHashContent[1]
        : "Warranty"
    );
  }, [getHashContent]);

  const validatePath = (e) => {
    if (e.name.replace(/\s/g, "") === active) {
      return <EMI subCatId={e.id} />;
    }
  };

  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="profile-page-wrapper w-full">
        <div className="container-x mx-auto">
          <div className="w-full my-10">
            <BreadcrumbCom
              paths={[
                { name: "home", path: "/" },
                { name: "assurance", path: "/assurance" },
              ]}
            />
            <div className="w-full bg-white px-10 py-9">
              <div className="title-area w-full flex justify-between items-center">
                <h1 className="text-[22px] font-bold text-qblack">Assurance</h1>
                <div className="title-area flex flex-col justify-center items-center relative text-center">
                  <h1 className="text-[34px] font-bold leading-[74px] text-qblack">
                    {active}
                  </h1>
                  <div className="shape -mt-5">
                    <svg
                      width="172"
                      height="29"
                      viewBox="0 0 172 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 5.08742C17.6667 19.0972 30.5 31.1305 62.5 27.2693C110.617 21.4634 150 -10.09 171 5.08727"
                        stroke="#FFBB38"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="profile-wrapper w-full mt-8 flex space-x-10">
                <div className="w-[236px] min-h-[600px] border-r border-[rgba(0, 0, 0, 0.1)]">
                  <div className="flex flex-col space-y-10">
                    <DataIteration
                      datas={assurance}
                      startLength={0}
                      endLength={assurance.length}
                    >
                      {({ datas }) => (
                        <div key={datas.id}>
                          <ul>
                            <li>
                              <div className="item group">
                                <Link
                                  to={
                                    "/assurance#" +
                                    datas.name.replace(/\s/g, "")
                                  }
                                >
                                  <button
                                  //   onClick={() => validateSubCategories(datas)}
                                  >
                                    <div className="flex space-x-3 items-center text-qgray hover:text-qblack">
                                      <span>
                                        <FaWpforms />
                                      </span>
                                      <span className=" font-normal text-base">
                                        {datas.name}
                                      </span>
                                    </div>
                                  </button>
                                </Link>
                              </div>
                            </li>
                          </ul>
                        </div>
                      )}
                    </DataIteration>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="item-body dashboard-wrapper w-full">
                    {active === "Warranty" ? (
                      <Warranty />
                    ) : active === "Insurance" ? (
                      <Insurance />
                    ) : active === "EMI" ? (
                      <EMI />
                    ) : (
                      <FourZeroFour />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
