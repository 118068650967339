import "./styles.css";

export default function ShareMenu() {
  return (
    <div class="sticky-container">
      <ul class="sticky">
        <li>
          <img
            src="/assets/images/whatsapp-circle.png"
            width="32"
            height="32"
          />
          <p>
            <a href="" target="blank">
              Message us from
              <br />
              Whats app
            </a>
          </p>
        </li>
        <li>
          <img
            src="/assets/images/instagram-circle.png"
            width="32"
            height="32"
          />
          <p>
            <a href="https://www.instagram.com/" target="_blank">
              Follow Us on
              <br />
              Instagram
            </a>
          </p>
        </li>
        <li>
          <img src="/assets/images/youtube-circle.png" width="32" height="32" />
          <p>
            <a href="https://www.youtube.com/" target="_blank">
              Follow Us on
              <br />
              Youtube
            </a>
          </p>
        </li>
        <li>
          <img
            src="/assets/images/facebook-circle.png"
            width="32"
            height="32"
          />
          <p>
            <a href="https://www.facebook.com/" target="_blank">
              Follow Us on
              <br />
              Facebook
            </a>
          </p>
        </li>
      </ul>
    </div>
  );
}
