import { API_URL } from "../utils/ApiUrl";
import ServiceCalls from "../utils/services/httpServiceCalls";

export const registerUser = (data) => async () => {
  try {
    let result = await ServiceCalls.post(
      `${API_URL.USER_REGISTER}`,
      data,
      true
    );
    return result;
  } catch (error) {}
};

export const updateUser = (data) => async () => {
  try {
    let result = await ServiceCalls.post(
      `${API_URL.USER_UPDATE}${data.id}`,
      data,
      true
    );
    return result;
  } catch (error) {}
};
