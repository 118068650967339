import axios from "axios";
import { BASE_URL } from "../ApiUrl";

export const axiosApi = axios.create({
  baseURL: BASE_URL.BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  validateStatus: function (status) {
    handleResponse(status);
    return status >= 200 && status < 300; // default
  },
});

function handleResponse(response) {
  if (response >= 400) {
    if (response === 401) {
      // auto logout if 401 response returned from api
    }
  } else if (response === 301) {
    // console.log(response);
  }
  return response;
}
