import { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputCom from "../../components/Helpers/InputCom";

export default function Insurance() {
  const navigate = useNavigate();
  const [loginForm, setLoginForm] = useState({
    userName: "",
    password: "",
  });

  const login = async () => {};

  const handleChanges = (e) => {
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value });
  };

  const showAlert = (message) => {
    alert(message);
  };

  return (
    <div>
      <div className="lg:flex relative">
        <div className="lg:w-[800px] w-full h-[600px] bg-white flex flex-col sm:p-10 p-5 border border-[#E0E0E0]">
          <div className="w-full">
            <div className="input-area">
              <div className="input-item mb-5">
                <InputCom
                  placeholder="Name"
                  label="Name*"
                  name="userName"
                  type="email"
                  inputClasses="h-[50px]"
                  inputHandler={(e) => handleChanges(e)}
                />
              </div>
              <div className="input-item mb-5">
                <InputCom
                  placeholder="example@mail.com"
                  label="Email Address*"
                  name="userName"
                  type="email"
                  inputClasses="h-[50px]"
                  inputHandler={(e) => handleChanges(e)}
                />
              </div>
              <div className="input-item mb-5">
                <InputCom
                  placeholder="xxx xxx xxxx"
                  label="Phone Number*"
                  name="userName"
                  type="email"
                  inputClasses="h-50"
                  inputHandler={(e) => handleChanges(e)}
                />
              </div>
              <div className="flex sm:flex-row flex-col space-y-5 sm:space-y-0 sm:space-x-5 mb-5">
                <InputCom
                  placeholder="Select"
                  label="Select Bike*"
                  name="userName"
                  type="email"
                  inputClasses="h-[50px]"
                  inputHandler={(e) => handleChanges(e)}
                />
                <InputCom
                  placeholder="frame no."
                  label="Frame No.*"
                  name="userName"
                  type="email"
                  inputClasses="h-[50px]"
                  inputHandler={(e) => handleChanges(e)}
                />
                <InputCom
                  placeholder="Invoice no."
                  label="Invoice Number*"
                  name="userName"
                  type="email"
                  inputClasses="h-[50px]"
                  inputHandler={(e) => handleChanges(e)}
                />
              </div>
              <div>
                <button
                  onClick={login}
                  type="button"
                  className="black-btn mb-6 text-sm text-white w-[200px] h-[50px] font-semibold flex justify-center bg-purple items-center"
                >
                  <span>Upload Invoice</span>
                </button>
              </div>
              <div className="signin-area mb-3.5">
                <div className="flex justify-center">
                  <button
                    onClick={login}
                    type="button"
                    className="black-btn mb-6 text-sm text-white w-full h-[50px] font-semibold flex justify-center bg-purple items-center"
                  >
                    <span>Submit</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="flex-1 lg:flex hidden transform scale-60 xl:scale-100   xl:justify-center ">
            <div
              className="absolute xl:-right-20 -right-[138px]"
              style={{ top: "calc(50% - 258px)" }}
            >
              <Thumbnail />
            </div>
          </div> */}
      </div>
    </div>
  );
}
