import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAllProductsRequest } from "../AllProductPage/httpactions/httpactions";
import SectionStyleThree from "../Helpers/SectionStyleThree";
import SectionStyleTwo from "../Helpers/SectionStyleTwo";
import ViewMoreTitle from "../Helpers/ViewMoreTitle";
import LayoutHomeThree from "../Partials/LayoutHomeThree";
import Banner from "./Banner";
import CampaignCountDown from "./CampaignCountDown";
import ProductsAds from "./ProductsAds";
import gloves from "./gloves.gif";
import helmet from "./helmet.gif";

export default function HomeThree() {
  const [products, setProducts] = useState([]);
  const dispatch = useDispatch();
  const [topsellingProducts, setTopSellingProducts] = useState([]);

  useEffect(() => {
    console.log("");
    getAllProducts();

    //eslint-disable-next-line
  }, []);

  const getAllProducts = async () => {
    await dispatch(
      getAllProductsRequest(
        { userId: localStorage.getItem("userId") },
        dispatch
      )
    ).then((response) => {
      if (response !== undefined) {
        console.log(response.result);
        setProducts(response.result);
        updateTopsellingProducts(response.result);
      }
    });
  };

  const updateTopsellingProducts = (results) => {
    let arr = [];
    const track = results.filter((obj) => obj.name.toLowerCase() == "trac");
    const buzz = results.filter((obj) => obj.name.toLowerCase() == "buzz");
    const haul = results.filter((obj) => obj.name.toLowerCase() == "haul");
    const dyna = results.filter((obj) => obj.name.toLowerCase() == "dyna");

    if (track.length > 0) {
      arr.push(track[0]);
    }
    if (buzz.length > 0) {
      arr.push(buzz[0]);
    }
    if (haul.length > 0) {
      arr.push(haul[0]);
    }
    if (dyna.length > 0) {
      arr.push(dyna[0]);
    }

    setTopSellingProducts(arr);
  };
  return (
    <>
      <LayoutHomeThree type={3} childrenClasses="pt-0">
        <Banner className="" />
        {/* <BrandSection
          type={3}
          sectionTitle="Shop by Brand"
          className="brand-section-wrapper mb-[60px]"
        /> */}
        <SectionStyleThree
          type={3}
          products={products}
          sectionTitle="New Arrivals"
          seeMoreUrl="/all-products"
          className="new-products mb-[60px]"
        />

        <ProductsAds
          ads={[`${process.env.PUBLIC_URL}/assets/images/hels.jpeg`]}
          className="products-ads-section mb-[60px]"
        />
        <div className="aboutus-wrapper w-full">
          <div className="container-x mx-auto">
            <div className="w-full min-h-[300px] lg:flex lg:space-x-12 items-center pt-20">
              <div className="md:w-[400px] w-full md:h-[300px] lr:20 h-auto rounded overflow-hidden">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/vision.jpg`}
                  alt="about"
                  className="w-full"
                />
              </div>
              <div className="content flex-1">
                <h1 className="text-[18px] font-medium text-qblack mb-2.5">
                  OUR VISION
                </h1>
                <p className="text-[15px] text-qgraytwo leading-7 mb-2.5">
                  At ECO AUTOMOTIVE PVT LTD, we believe that everyone should
                  have access to high-quality products at affordable prices. Our
                  vision is to become the go-to destination for shoppers who are
                  looking for the best products at the most competitive prices.
                  We strive to create a shopping experience that is easy,
                  enjoyable, and stress-free.
                </p>
              </div>
            </div>
            <div className="w-full min-h-[300px] lg:flex lg:space-x-12 items-center pt-10">
              <div className="content flex-1">
                <h1 className="text-[18px] font-medium text-qblack mb-2.5">
                  OUR PHILOSOPHY
                </h1>
                <p className="text-[15px] text-qgraytwo leading-7 mb-2.5">
                  Our philosophy is simple: put the customer first. We believe
                  that by focusing on our customers' needs, we can create a
                  business that is sustainable and successful. We are committed
                  to providing exceptional customer service and are always
                  looking for ways to improve our customers' experience.
                </p>
              </div>
              <div className="md:w-[400px] w-full md:h-[300px] h-auto rounded overflow-hidden my-5 lg:my-0">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/philosophy.jpg`}
                  alt="about"
                  className="w-full"
                />
              </div>
            </div>
            <div className="w-full min-h-[300px] lg:flex lg:space-x-12 items-center pt-20 pb-40">
              <div className="md:w-[400px] w-full md:h-[300px] lr:20 h-auto rounded overflow-hidden">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/product.png`}
                  alt="about"
                  className="w-full"
                />
              </div>
              <div className="content flex-1">
                <h1 className="text-[18px] font-medium text-qblack mb-2.5">
                  OUR PRODUCTS
                </h1>
                <p className="text-[15px] text-qgraytwo leading-7 mb-2.5">
                  We offer a wide selection of high-quality products. We work
                  with trusted manufacturers to ensure that our products meet
                  the highest standards of quality. Our products are
                  competitively priced, so you can be sure that you are getting
                  the best value for your money.
                </p>
              </div>
            </div>
          </div>
        </div>

        <ProductsAds
          ads={[helmet, gloves]}
          sectionHeight="sm:h-[295px] h-full"
          className="products-ads-section mb-[60px]"
        />
        <ViewMoreTitle
          className="top-selling-product mb-[60px]"
          seeMoreUrl="/all-products"
          categoryTitle="Top Selling Products"
        >
          <SectionStyleTwo type={3} products={topsellingProducts} />
        </ViewMoreTitle>

        <CampaignCountDown
          className="mb-[60px]"
          lastDate="2023-10-24 4:00:00"
        />
      </LayoutHomeThree>
    </>
  );
}
