import {
  addWishListItemsById,
  getWishListItemsById,
  productAddToCartById,
  removeWishListItemsById,
  userCartListById,
  userCartRemoveItemById,
  userCartTotalById,
  userClearCartById,
} from "../../../api/productsservices";

const getWishListItemsRequest = (data) => async (dispatch) => {
  const response = await dispatch(getWishListItemsById(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

const addWishListItemsRequest = (data) => async (dispatch) => {
  const response = await dispatch(addWishListItemsById(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

const removeWishListItemsRequest = (data) => async (dispatch) => {
  const response = await dispatch(removeWishListItemsById(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

const productAddToCartByIdRequest = (data) => async (dispatch) => {
  const response = await dispatch(productAddToCartById(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

const carListByIdRequest = (data) => async (dispatch) => {
  const response = await dispatch(userCartListById(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

const carTotalByIdRequest = (data) => async (dispatch) => {
  const response = await dispatch(userCartTotalById(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

const removeCartItemByIDRequest = (data) => async (dispatch) => {
  const response = await dispatch(userCartRemoveItemById(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

const clearCartItemsByIDRequest = (data) => async (dispatch) => {
  const response = await dispatch(userClearCartById(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status === 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

export {
  addWishListItemsRequest,
  carListByIdRequest,
  carTotalByIdRequest,
  clearCartItemsByIDRequest,
  getWishListItemsRequest,
  productAddToCartByIdRequest,
  removeCartItemByIDRequest,
  removeWishListItemsRequest,
};
