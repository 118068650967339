import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import InputQuantityCom from "../Helpers/InputQuantityCom";
import { removeCartItemByIDRequest } from "../SingleProductPage/httppactions/httpactions";

export default function ProductsTable({ className, data }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cartList, setCartList] = useState(data);

  const showProductDetails = (e) => {
    navigate("/single-product", { state: e });
  };

  const removeFromCart = async (e) => {
    await dispatch(removeCartItemByIDRequest({ productId: e })).then(
      (response) => {
        if (response.statusCode == 200) {
          alert("Removed successfully");
          window.location.reload();
        } else {
          alert("Unable to remove");
        }
      }
    );
  };

  const handleCartItemChanges = (item, quantity) => {
    const myNextList = [...cartList];
    const object = myNextList.find((a) => a.id === item.id);
    object.cartQuantity = quantity;
    setCartList(myNextList);
  };
  return (
    <div className={`w-full ${className || ""}`}>
      <div className="relative w-full overflow-x-auto border border-[#EDEDED]">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-[13px] font-medium text-black bg-[#F6F6F6] whitespace-nowrap px-2 border-b default-border-bottom uppercase">
            <tr className="text-[13px] font-medium text-black bg-[#F6F6F6] whitespace-nowrap px-2 border-b default-border-bottom uppercase">
              <th className="py-4 pl-10 block whitespace-nowrap  w-[380px]">
                product
              </th>
              <th className="py-4 whitespace-nowrap text-center">Price</th>
              <th className="py-4 whitespace-nowrap  text-center">Quantity</th>
              <th className="py-4 whitespace-nowrap  text-center">Total</th>
              <th className="py-4 whitespace-nowrap text-right w-[114px] block"></th>
            </tr>
          </thead>
          <tbody>
            {cartList.map((item, i) => {
              return (
                <tr className="bg-white border-b hover:bg-gray-50">
                  <td className="pl-10  py-4 ">
                    <div className="flex space-x-6 items-center">
                      <div className="w-[80px] h-[80px] overflow-hidden flex justify-center items-center border border-[#EDEDED]">
                        <img
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/images/${item.name.toLowerCase()}1.png`}
                          alt="product"
                          className="w-full h-full object-contain"
                        />
                      </div>
                      <div className="flex-1 flex flex-col">
                        <p
                          className="font-medium text-[15px] text-qblack hover:text-blue-600"
                          onClick={() => showProductDetails(item)}
                        >
                          {item.name}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="text-center py-4 px-2">
                    <div className="flex space-x-1 items-center justify-center">
                      <span className="text-[15px] font-normal">
                        ₹{item.discounted_price}
                      </span>
                    </div>
                  </td>

                  <td className=" py-4">
                    <div className="flex justify-center items-center">
                      <InputQuantityCom
                        handler={(e) => {
                          console.log(e);
                          handleCartItemChanges(item, e);
                        }}
                        productQuantity={item.cartQuantity}
                      />
                    </div>
                  </td>
                  <td className="text-right py-4">
                    <div className="flex space-x-1 items-center justify-center">
                      <span className="text-[15px] font-normal">
                        ₹{item.discounted_price * item.cartQuantity}
                      </span>
                    </div>
                  </td>
                  <td className="text-right py-4">
                    <div className="flex space-x-1 items-center justify-center">
                      <span onClick={() => removeFromCart(item.cartId)}>
                        <svg
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.7 0.3C9.3 -0.1 8.7 -0.1 8.3 0.3L5 3.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L3.6 5L0.3 8.3C-0.1 8.7 -0.1 9.3 0.3 9.7C0.7 10.1 1.3 10.1 1.7 9.7L5 6.4L8.3 9.7C8.7 10.1 9.3 10.1 9.7 9.7C10.1 9.3 10.1 8.7 9.7 8.3L6.4 5L9.7 1.7C10.1 1.3 10.1 0.7 9.7 0.3Z"
                            fill="#AAAAAA"
                          />
                        </svg>
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
