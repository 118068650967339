import BreadcrumbCom from "../BreadcrumbCom";
import Layout from "../Partials/Layout";
import myvideo from "./home.mp4";

export default function VirtualShowroom() {
  return (
    <Layout>
      <div className="cart-page-wrapper w-full">
        <div className="container-x mx-auto">
          <BreadcrumbCom paths={[{ name: "home", path: "/" }]} />
          <div className="empty-card-wrapper w-full">
            <div className="flex justify-center items-center w-full">
              <video width="800" height="500" controls>
                <source src={myvideo} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
